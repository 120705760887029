@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
  padding: 8px;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  border-color: red !important;
}

.loadingItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  /* z-index: 10; */
}

#main {
  position: relative;
  min-width: 250px;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  -webkit-text-size-adjust: auto;
  color: #000;
}

span.tdttbox {
  height: auto !important;
}

body {
  overflow-y: auto;
}

.page1,
.page2,
.page3 {
  break-inside: avoid;
}

.pop_a4size_cus {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  z-index: 2;
  border-radius: 15px;
  box-shadow: -10px 10px 60px rgba(58, 61, 61, 0.5);
  overflow: hidden;
  z-index: 5;
}

.a4box > ul > li.page {
  align-items: center;
}

.a4box > ul > li.page3 {
  background: #fff url('../public/images/a4bg2.png') repeat-y !important;
  background-size: 100% 29.7cm !important;
  align-items: center;
}

.mt-18 {
  margin-top: 4.5rem;
}
.mt-20 {
  margin-top: 5rem;
}
