/************************ 메인 공통 ****ords*********************/

/*스크롤 부드럽게 이동*/
html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/*스크롤 부드럽게 이동*/
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  /*overflow-x: hidden;*/
}

#wrap {
  position: relative;
  min-width: 250px;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  -webkit-text-size-adjust: auto;
  color: #000;
  /*font-family: 'Nanum Gothic', ‘맑은 고딕’, ‘Malgun Gothic’, sansa-serif;*/
}

/****contents ****/

/****intro ****/
.intro1,
.intro2 {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background: #000;
}

/*text*/
.intro_ttbox {
  position: relative;
  z-index: 9;
}

.intro_ttbox > dt {
  font-size: 34px;
  color: #fff;
  font-weight: 700;
}

.intro_ttbox > dd {
  font-size: 17px;
  opacity: 0.5;
  color: #fff;
}

.intro_ttbox > dd.btn_box1 {
  display: inline-block;
  line-height: 45px;
  border: 1px solid #fff;
  width: 225px;
  text-align: center;
  opacity: 1;
  transition: all 0.2s;
}

.intro1:hover > .intro_ttbox > dd,
.intro2:hover > .intro_ttbox > dd {
  opacity: 1;
}

.intro1:hover > .intro_ttbox > dd.btn_box1,
.intro2:hover > .intro_ttbox > dd.btn_box1 {
  background: #000;
  border: 1px solid #000;
}

/*bg*/
.intro1 > p.bg,
.intro2 > p.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.2s;
}

.intro1 > p.bg {
  background: url('../../public/images/intro_img1_on.jpg') no-repeat;
  background-size: cover;
  opacity: 0.5;
}

.intro2 > p.bg {
  background: url('../../public/images/intro_img2_on.jpg') no-repeat;
  background-size: cover;
  opacity: 0.5;
}

.intro1:hover > p.bg {
  opacity: 1;
}

.intro2:hover > p.bg {
  opacity: 1;
}

.intro1:hover > p.bg,
.intro2:hover > p.bg {
  transform: scale3d(1.6, 1.6, 1) rotate3d(0, 0, 0, 0.01deg);
  -webkit-transform: scale3d(1.6, 1.6, 1) rotate3d(0, 0, 0, 0.01deg);
  -moz-transform: scale3d(1.6, 1.6, 1) rotate3d(0, 0, 0, 0.01deg);
  transition: transform 15s ease-out;
  -webkit-transition: -webkit-transform 15s ease-out;
  -moz-transition: -moz-transform 15s ease-out;
}

/****intro ****/

/**login**/
.login_bg1 {
  background: url('../../public/images/login_bg.jpg') no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login_box1 {
  position: relative;
  min-width: 250px;
  max-width: 500px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.4);
}

.login_inputbox input.login_input {
  font-size: 13px;
  color: #4b5259;
  appearance: none;
  display: inline-block;
  line-height: 55px;
  height: 55px;
  /*border: 1px solid #CED4DA;*/
  border-radius: 10px;
}

.login_inputbox input.login_input:focus {
  outline: 1px solid #ff6d19;
}

.login_inputbox input.login_input::placeholder {
  color: #797979;
}

.login_inputbox > li > .login_btn {
  color: #fff;
  appearance: none;
  display: inline-block;
  text-align: center;
  line-height: 55px;
  height: 55px;
  border-radius: 10px;
}

.login_inputbox > li > .login_btn1 {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #ff6d19),
    color-stop(1, #ff8b51)
  );
  /* background: -moz-linear-gradient(left, #ff6d19 0%, #ff8b51 100%); */
  background: -webkit-linear-gradient(left, #ff6d19 0%, #ff8b51 100%);
  /* background: -o-linear-gradient(left, #ff6d19 0%, #ff8b51 100%); */
  /* background: -ms-linear-gradient(left, #ff6d19 0%, #ff8b51 100%); */
  /* background: linear-gradient(left, #ff6d19 0%, #ff8b51 100%); */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6d19', endColorstr='#ff8b51', gradientType=1);
}
.login_inputbox > li > .login_btn1:hover,
.login_inputbox > li > .login_btn2:hover {
  background: #1e2d44 !important;
}

.login_inputbox > li > .find_btn:hover {
  text-decoration: underline;
  color: #ff6d19;
  text-decoration: underline;
}

/**login**/
/**join**/
.login_bg2 {
  background: url('../../public/images/login_bg.jpg') no-repeat;
  background-size: cover;
  height: 100%;
}

.join_left {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.join_right {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.joinbg1 {
  position: fixed;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
}

.joinbg2 {
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  box-shadow: -10px 0px 40px rgba(0, 0, 0, 0.5);
}

/**join**/
/****contents ****/

/*----------------------익스플로러만 적용되는 css---------------------*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
}

/*----------------------엣지에만 적용되는 css---------------------*/
@supports (-ms-ime-align: auto) {
}
