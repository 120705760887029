.popbox {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.pop_container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  z-index: 2;
  border-radius: 15px;
  box-shadow: -10px 10px 60px rgba(58, 61, 61, 0.5);
  max-height: 90%;
  overflow: hidden;
}

.pop_container > li {
  position: relative;
}

/*닫기*/
.close1 {
  position: absolute;
  right: 15px;
  top: -15px;
}

.close1:hover > img {
  filter: brightness(0);
}

.a4box .poptit > .tit,
.pop_container .poptit > .tit {
  font-family: 'Noto Sans KR';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.03rem;
}

.pop_contents {
  overflow-y: auto;
}

/*팝업닫기*/
.btn_pop {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-weight: 700;
}

.btn_pop:hover {
  color: #fff;
  background: #000000;
}

/*팝업닫기*/
.blur-it {
  filter: blur(2px);
}

.popbg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

/*20241028노후도평가 a4*/
.a4popbox {
  width: 100%;
  overflow-y: auto;
  align-items: stretch;
}

.a4popbox::after {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 140.14%;
}

.a4popbg {
  position: fixed;
}

.pop_a4size {
  position: absolute;

  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  z-index: 2;
  border-radius: 15px;
  box-shadow: -10px 10px 60px rgba(58, 61, 61, 0.5);
  z-index: 5;
  margin: 0 auto;
}

.pop_a4size::after {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 140.14%;
}

.a4box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgb(234, 234, 238);
}

.pop_a4size > .close1 {
  top: 15px;
  z-index: 5;
}

.a4box > ul {
  position: relative;
  height: 100%;
}

.a4box > ul > li {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.a4box li,
.a4box dt,
.a4box dd,
.a4box p,
.a4box span,
.a4box td,
.a4box th {
  font-family: 'Noto Sans KR';
}

.a4box > ul > li.page1,
.a4box > ul > li.page2 {
  background: #fff url('../../public/images/a4bg1.png') no-repeat;
  background-size: cover;
  align-items: center;
}

.a4box > ul > li.page3 {
  background: #fff url('../../public/images/a4bg2.png') no-repeat;
  background-size: cover;
  align-items: center;
}

.a4box > ul > li.page1 dt {
  letter-spacing: -0.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.a4box > ul > li > dl {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.cblue1 {
  color: #536eb5;
}

/*page1*/
span.listpoint1 {
  display: inline-block;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #536eb5;
  border-radius: 3px;
  font-weight: 500;
}

span.listpoint2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 15px;
  letter-spacing: -0.05rem;
  text-align: center;
  color: #536eb5;
  background: #f0f1f3;
  border-radius: 3px;
  font-weight: 500;
}

/*page2*/
.listbox > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url('../../public/images/a4_dot1.gif') repeat-x left center;
}

.listbox > li > p:first-child {
  letter-spacing: -0.035rem;
}

/*page3*/
.page_contents {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  overflow: auto;
}

.page_contents > table {
  width: 100%;
}

.page_contents > table th,
.page_contents > table td {
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 10px;
}

.page_contents > table th {
  color: #536eb5;
  background: #f0f1f3;
  text-align: center;
  font-size: 15px;
}
