.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.horizontal-slider {
  width: 95%;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}
.color_txt {
  position: relative;
  margin-top: 0.4rem;
  padding-left: 2.4rem;
  font-size: 1.3rem;
}
.color_txt::before {
  content: '';
  position: absolute;
  left: 0;
  top: -0.1rem;
  display: block;
  width: 2rem;
  height: 2rem;
  background-position: 50% 50%;
  background-size: 80%;
  background-repeat: no-repeat;
}

/* wordCloud 글자 커서 */
svg text {
  cursor: pointer;
}

/* .color_txt.type1 {
  color: var(--danger);
}
.color_txt.type2 {
  color: var(--warning);
}
.color_txt.type3 {
  color: var(--success);
}
.color_txt.type4 {
  color: var(--information);
} */
