/******main_base******/

/****sidemenu****/
.sidemenu {
  position: relative;
  flex-shrink: 0;
  width: 260px;
  height: 100%;
}

.mainside {
  background: #e0773f;
}

.subside {
  background: #f7eee8;
}

h1 > span {
  font-family: 'Noto Sans KR';
  font-size: 18px;
  font-weight: 700;
}

.sidemenu > .menu > li {
  display: flex;
  align-items: center;
  opacity: 0.7;
  line-height: 65px;
  font-size: 18px;
  cursor: pointer;
}

.sidemenu > .menu > li.sel {
  opacity: 1;
}

.sidemenu > .menu > li.sel > span {
  font-weight: 800;
}

/****sidemenu****/

/****cotainer****/

/**header**/
header {
  position: relative;
}

header > dl > dt,
header > dl > dd {
  height: 110px;
  display: flex;
  align-items: center;
}

.header_tit {
  border-bottom: 2px solid #e7ebef;
}

/*leftbtn*/
.btn_top {
  position: absolute;
  top: 70px;
  left: -18px;
  z-index: 5;
}

.btn_top > a {
  position: absolute;
  top: 19px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: centers;
  width: 41px;
  height: 41px;
  border-radius: 100px;
}

.btn_top > a:hover {
  background: #ff8647;
}

.btn_top > a > img {
  position: relative;
  object-fit: contain;
}

/*leftbtn*/

.gnb {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e0773f;
}

/*알람쪽지*/
.gnb_icon {
  position: relative;
}

.gnb_icon > span.new {
  position: absolute;
  right: -4px;
  top: -3px;
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #e0773f;
  border: 2px solid #fff;
  box-sizing: content-box;
  border-radius: 100px;
  z-index: 5;
}

.gnb_icon > img {
  filter: brightness(0);
}

.gnb_icon:hover > img {
  filter: brightness(100%);
}

.gnb_icon:hover > span.new {
  background: #000;
}

/*알람쪽지*/
/*프로필이미지*/
.gnb_profile > p.img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  overflow: hidden;
}

/*프로필이미지*/
/**header**/
/****cotainer****/

/******main_base******/

/******footer******/
footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/******contents******/

.containerbox {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
}

.scoroll_auto {
  overflow-y: auto;
}

.contentsbox {
  flex-grow: 1;
  overflow-y: auto;
}

.contentsbox > .conbox {
  flex-grow: 1;
  overflow-y: auto;
}

/*depth1_tab*/
.depth1_tab {
  position: relative;
  background: #f4f4f7;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 5px;
  height: 43px;
  box-sizing: content-box;
}

.depth1_tab > li,
.depth1_step > li {
  display: flex;
  align-items: center;
}

.depth1_tab > li > a {
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
  line-height: 43px;
  min-width: 115px;
  padding: 0 20px;
  text-align: center;
  border-radius: 100px;
  transition: background 0.2s;
}

.depth1_step {
  position: relative;
  background: #f4f4f7;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  display: flex;
  align-items: center;
  padding: 8px;
  height: 43px;
  box-sizing: content-box;
}

.depth1_step > li:first-child {
  position: relative;
  left: 0;
}

.depth1_step > li:nth-child(2) {
  position: relative;
  left: -15px;
}

.depth1_step > li:nth-child(3) {
  position: relative;
  left: -30px;
}

.depth1_step > li:nth-child(4) {
  position: relative;
  left: -45px;
}

.depth1_step > li:nth-child(5) {
  position: relative;
  left: -60px;
}

.depth1_step > li:nth-child(6) {
  position: relative;
  left: -75px;
}

.depth1_step > li > a {
  border-radius: 8px;
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
  height: 43px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .depth1_step > li > a > p {
  transition: background 0.2s;
} */

.depth1_step > li > a > p.tt {
  background: #dcdce9;
  height: 100%;
  line-height: 43px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.depth1_step > li > a > p.left {
  display: inline-block;
  background: url('../../public/images/step_left2.png') no-repeat;
  height: 100%;
  width: 20px;
}

.depth1_step > li > a > p.right {
  display: inline-block;
  background: url('../../public/images/step_right2.png') no-repeat;
  height: 100%;
  width: 20px;
}

.depth1_step > li > a:hover > p.tt,
.depth1_step > li > a.active > p.tt {
  background: #382f9c;
  height: 100%;
  line-height: 43px;
  color: #fff;
}

.depth1_step > li > a:hover > p.left,
.depth1_step > li > a.active > p.left {
  display: inline-block;
  background: url('../../public/images/step_left1.png') no-repeat;
  height: 100%;
  width: 20px;
}

.depth1_step > li > a:hover > p.right,
.depth1_step > li > a.active > p.right {
  display: inline-block;
  background: url('../../public/images/step_right1.png') no-repeat;
  height: 100%;
  width: 20px;
}

.depth1_step > li > a > p > .num {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 21px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 900;
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  margin-right: 7px;
}

.depth1_step > li > a:hover > p > .num,
.depth1_step > li > a.active > p > .num {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}

.depth1_step > li > a .state_icon > img {
  filter: brightness(0);
  opacity: 0.3;
}

.depth1_step > li > a:hover .state_icon > img,
.depth1_step > li > a.active .state_icon > img {
  filter: none;
  opacity: 1;
}

/*depth1_tab*/
/*depth2_tab*/
.depth2_tab {
  position: relative;
  background: #ffffff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.depth2_tab > li > a {
  color: #a7a7ba;
  font-size: 15px;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  transition: background 0.2s;
  line-height: 43px;
  border-bottom: 3px solid #fff;
}

.depth2_tab > li > a:hover,
.depth2_tab > li > a.active {
  color: #382f9c;
  border-bottom: 3px solid #382f9c;
}

/*depth2_tab*/
/*depth3_tab*/
.depth3_tab {
  width: 185px;
  border: 1px solid #d2cfe9;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.depth3_tab > li > a {
  font-size: 15px;
  display: flex;
  color: #606075;
  padding: 6px 15px 6px 20px;
  border-radius: 5px;
  transition: all 0.2ss;
}

.depth3_tab > li > a:hover,
.depth3_tab > li > a.active {
  color: #fff;
  background: #9190a3;
}

.depth3_tab > li > a:hover > span,
.depth3_tab > li > a.active > span {
  color: #fff;
  font-weight: 700;
}

/*depth3_tab*/
/******contents******/
/******1프로젝트메인******/

.input_hauto {
  color: #382f9c;
}

.input_h45 {
  color: #382f9c;
  height: 45px;
  border: 1px solid #d2cfe9;
}

.textarea1:focus,
.input_h45:focus {
  border: 1px solid #382f9c;
  outline: none;
}

.input_hauto:focus {
  outline: 1px solid #382f9c;
}

.textarea1::placeholder,
.input_hauto::placeholder,
.input_h45::placeholder {
  color: #8d8ac0;
}

.textarea1 {
  border: 1px solid #d2cfe9;
}

.btn_h25 {
  display: inline-block;
  line-height: 23px;
  height: 25px;
  transition: all 0.2s;
  font-size: 12px;
}

.btn_h30 {
  display: inline-block;
  line-height: 28px;
  height: 30px;
  transition: all 0.2s;
  font-size: 12px;
}

.btn_h35 {
  line-height: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  font-size: 14px;
  letter-spacing: -0.02rem;
}

.btn_h45 {
  line-height: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.btn_line {
  color: #33313f;
  border: 2px solid #33313f;
}

.btn_line:hover {
  color: #fff;
  background: #33313f;
}

.btn_blue {
  color: #fff;
  background: #382f9c;
}

.btn_gray:hover,
.btn_blue:hover {
  background: #e0773f;
}

.btn_gray {
  color: #fff;
  background: #6e6d79;
}

.btn_delimg > img {
  filter: brightness(0);
}

.btn_delimg:hover > img {
  opacity: 1;
  filter: brightness(100%);
}

/*버튼 비활성화*/
.deactivation,
.deactivation:hover {
  cursor: default !important;
  color: rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) inset;
  background: rgba(0, 0, 0, 0.08);
  border: none;
}

/*card_view*/
.card_list > li {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.2s;
}

.items::after {
  display: block;
  content: '';
  padding-bottom: 128%;
}

.items:hover {
  z-index: 1;
  box-shadow: 0 20px 25px -5px rgba(136, 145, 158, 0.9);
}

.items > p {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background: red;
}

/*프로젝트 텍스트구성*/
.items_ttbox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.items_ttbox dl {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #d4d3e2;
}

.items_ttbox dl > dt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.items_ttbox dl > dt .tit1 {
  font-family: 'Noto Sans KR';
  letter-spacing: -0.05rem;
  font-weight: 600;
  /*border-top: 2px solid #e0773f;
    border-bottom: 2px solid #333231;
    color: #fff;*/
  display: inline-block;
  width: 100%;
}

.items_ttbox dl p.data {
  display: inline-block;
  background: rgb(78, 73, 131);
  height: 30px;
  line-height: 28px;
  color: #fff;
  border-radius: 100px;
  font-weight: 700;
  font-family: 'Noto Sans KR';
  font-size: 14px;
}

.items_ttbox dl > dd.tt2 {
  color: #fff;
  background: #a7a7ba;
}

/*프로젝트 텍스트구성*/

/*프로젝트생성*/
.items > p > img {
  object-fit: cover;
  height: 100%;
}

.items_plus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed #cbcbd4;
}

.items_plus > p.plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background: #f5e2d7;
  border-radius: 100px;
}

.items_plus:hover {
  border: 2px dashed #e0773f;
  box-shadow: none;
}

.items_plus:hover > p.plus {
  background: #e0773f;
}

.items_plus:hover > p.plus > img {
  filter: brightness(0) invert(100);
}

/*프로젝트생성*/
/*card_view*/
/*page*/
.page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page > li {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: #f0f0f5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.page > li:hover,
.page > li.active {
  font-weight: 900;
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 4px;
  background: #b2b1be;
}

.page > li:hover > img {
  opacity: 1;
  filter: brightness(0) invert(100);
}

.page > li.deactivation,
.page > li.deactivation:hover {
  cursor: default;
  color: #b9b9b9 !important;
  background: #e0e0e4 !important;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1) inset;
}

.page > li.deactivation:hover > img {
  opacity: 0.3;
  filter: brightness(0) invert(0);
}

/*page*/

/***1_2프로젝트생성***/

/***1_3프로젝트내용***/

/***1_3프로젝트내용***/

/******1프로젝트메인******/

/**1프로젝트 기본정보**/
.icon1 {
  background: url('../../public/images/icon_plus1.png') no-repeat left 9px;
  background-size: 8px;
}

/***1프로젝트 기본정보**/
/***2분석대상**/

/*tabs*/
#tabs {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
}

#tabs a {
  display: inline-block;
  font-size: 15px;
  line-height: 35px;
  background: #fff;
  border: 1px solid #ddd;
  color: #000;
  border-radius: 5px;
}

#tabs a:hover,
#tabs a:hover::after,
#tabs a:focus,
#tabs a:focus::after,
#tabs #current a,
#tabs #current a::after {
  color: #fff;
  background: #000;
  border: 1px solid #000;
  letter-spacing: 0;
}

#tabs a:focus {
  /*outline: 0;*/
}

#tabs a::after {
  z-index: 1;
}

/*tabs*/

/*스위치*/
.switch {
  position: absolute;
  /* hidden */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.switch_label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 45px;
  height: 25px;
  background: #fff;
  border: 2px solid rgb(192, 195, 202);
  border-radius: 20px;
  transition: 0.2s;
}

.switch_label:hover {
  background: #efefef;
}

.onf_btn {
  position: absolute;
  top: 2px;
  left: 2px;
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 20px;
  background: #94939e;
  transition: 0.2s;
}

.switch:checked + .switch_label {
  background: #382f9c;
  border: 2px solid #382f9c;
}

.switch:checked + .switch_label:hover {
  background: #382f9c;
}

/* move */
.switch:checked + .switch_label .onf_btn {
  left: 23px;
  background: #fff;
}

/*스위치*/
/***2분석대상**/
/***1프로젝트 기본정보**/
/******3AI분석_1홈******/
.aibox > li {
  position: relative;
}

.ai_chk {
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: url('../../public/images/check_icon_g.png') no-repeat center
    center;
  border: 1px solid#e3e3e3;
  appearance: none;
  z-index: 1;
}

.ai_ttbox {
  position: relative;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  height: 100%;
}

.ai_chk:checked {
  background: #e0773f url('../../public/images/check_icon_w.png') no-repeat
    center center;
  border: 1px solid#e0773f;
}

.ai_chk:checked ~ .ai_ttbox {
  border: 1px solid #e0773f;
}

span.ai_state {
  font-size: 13px;
  display: inline-block;
  line-height: 30px;
}

/*로딩중*/
.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}

.loading > div {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  background-color: #fff;
  border-radius: 50%;
  transform: scale(0);
  animation: lodingCircle1 ease-in-out 2.5s infinite;
}

.loading > .loding1 {
  animation-delay: -0.6s;
}

.loading > .loding2 {
  animation-delay: -0.4s;
}

.loading > .loding3 {
  animation-delay: -0.2s;
}

@keyframes lodingCircle1 {
  100%,
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    opacity: 1;
  }

  10% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
  }

  60%,
  90% {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    opacity: 0;
  }
}

/*로딩중*/

footer a {
  display: inline-block;
  line-height: 32px;
  border-radius: 4px;
  border: 1px solid #e4e3f1;
  background: #f9f9fb;
  color: #8480bb;
  letter-spacing: 0.2rem;
}

footer a:hover {
  color: #fff;
  text-decoration: underline;
  background: #8480bb;
  border: 1px solid #8480bb;
}

/******3AI분석_1홈******/

/******3AI분석_1이력리스트******/
.statebox {
  font-size: 13px;
  display: inline-block;
  height: 28px;
  line-height: 28px;
  color: #fff;
}

.statebox1 {
  animation: blink-effect 0.5s step-end infinite;
  background: #f83f3f;
  color: #fff;
}

@keyframes blink-effect {
  50% {
    background: #382f9c;
  }
}

/******3AI분석_1이력리스트******/
/******3AI분석_3_3분석결과******/
.pointbox {
  display: flex;
  align-items: center;
  background: #3c3a4a;
  border-radius: 100px;
}

.pointbox > .img {
  width: 43px;
  height: 43px;
  border-radius: 100px;
  overflow: hidden;
  background: #fff;
}

thead.thead1 > tr > th {
  color: #382f9c;
  font-weight: 700;
  height: 55px;
  background: #f4f4f7;
  border: 1px solid #d2cfe9;
  word-break: keep-all;
}

thead.thead2 > tr > th {
  color: #382f9c;
  font-weight: 700;
  background: #f4f4f7;
  border: 1px solid #d2cfe9;
}

tbody.tbody1 > tr > td {
  font-weight: 500;
  color: #78768b;
  border: 1px solid #dddbee;
  word-break: keep-all;
}

span.tdttbox {
  display: inline-block;
  line-height: 25px;
  height: 25px;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
}

.numbox1 {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  width: 23px;
  height: 23px;
  line-height: 22px;
  border-radius: 4px;
  font-family: 'Noto Sans KR';
  text-align: center;
}

.point_bar {
  padding-bottom: 1px;
  background: url('../../public/images/point_bar1.png') repeat-x left 11px;
  background-size: 3px;
}

/**입찰 안내서 기본분석 결과**/

/******3AI분석_3_3분석결과******/
