/*base*/
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  word-break: keep-all;
  font-family: 'Nanum Gothic', ‘맑은 고딕’, ‘Malgun Gothic’, sansa-serif;
  color: #000;
  letter-spacing: -0.015rem !important;
}

form {
  margin: 0;
  padding: 0;
  border: 0 none;
  width: 100%;
}

/*color*/
.tt_orange1 {
  color: #e0773f;
}

/*background*/
.bg_orange1 {
  background: #f5e2d7;
}

.bg_orange2 {
  background: #ff8647;
}

.bg_orange3 {
  background: #e0773f;
}

/*font*/
.f_11 {
  font-size: 11px;
}

.f_13 {
  font-size: 13px;
}

.f_15 {
  font-size: 15px;
}

.f_17 {
  font-size: 17px;
}

.f_19 {
  font-size: 19px;
}

.f_22 {
  font-size: 22px;
}

.f_25 {
  font-size: 25px;
}

.f_34 {
  font-size: 34px;
}

.f_malgun {
  font-family: ‘맑은 고딕’, ‘Malgun Gothic’, sansa-serif;
  letter-spacing: -0.07em;
}

.f_noto {
  font-family: 'Noto Sans KR' !important;
}

.r3 {
  border-radius: 3px;
}

.r4 {
  border-radius: 4px;
}

.r5 {
  border-radius: 5px;
}

.r100 {
  border-radius: 100px;
}

/*input*/
.input1 {
  border-radius: 8px;
  border: 1px solid #ddd;
  color: #000;
  height: 45px;
}

.input1::placeholder {
  color: #8a92a6;
}

.input1:focus,
.input2:focus {
  border: 1px solid #2f6fed;
  outline: none;
}

.input2 {
  border-radius: 8px;
  color: #000;
  height: 48px;
  border: 1px solid #32ade6;
  background: #f8fafc;
}

/*input*/
/*textarea*/
.textarea1 {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

/*textarea*/
/*file*/
/*checkbox*/
.check_box1,
.check_box2 {
  cursor: pointer;
}

.check_box1 > input {
  position: relative;
  width: 22px;
  height: 22px;
  appearance: none;
  background: #fff url('../../public/images/check_icon_g.png') no-repeat center
    center;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.check_box1 > input:checked {
  background: #e0773f url('../../public/images/check_icon_w.png') no-repeat
    center center;
  border: 1px solid #e0773f;
}

.check_box2 > input {
  position: relative;
  width: 19px;
  height: 19px;
  appearance: none;
  background: rgba(0, 0, 0, 0.2) url('../../public/images/dot_white.png')
    no-repeat center center;
  background-size: 7px;
}

.check_box2 > input:checked {
  background: #e0773f url('../../public/images/dot_white.png') no-repeat center
    center;
  background-size: 7px;
}

.check_box2 > input:checked + span {
  font-weight: 800;
}

/*checkbox*/

/*select*/
select::-ms-expand {
  display: none;
}

select.select1 {
  position: relative;
  font-weight: 700;
  display: inline-block;
  font-size: 14px;
  color: #000;
  background: #fff url('../../public/images/arrow_down1.png') no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 15px;
  line-height: 28px;
  height: 30px;
  border-radius: 5px;
  -webkit-radius: 5px;
  -moz-border-radius: 5px;
  padding-left: 15px;
  padding-right: 35px;
  border: 1px solid #e3e3e3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.select1:focus {
  color: #e0773f;
  border: 1px solid #e0773f;
  background: #fff url('../../public/images/arrow_down2.png') no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 15px;
  outline: none;
}

/*btn*/

/**/

/*img*/
.imgt1 {
  position: relative;
  top: -0.1rem;
}

.imgt2 {
  position: relative;
  top: -0.13rem;
}

.imgt2 {
  position: relative;
  top: -0.06rem;
}

.imgwhite {
  filter: brightness(0) invert(100);
}

/*flex*/
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*slide_btn*/

/*slide_btn*/

/*파일등록*/
/*file등록*/
.filebox .upload-name {
  font-size: 13px;
  display: inline-block;
  color: #2a3042;
  border: 1px solid #ced4da;
  background: #eff2f7;
  line-height: 37px;
  height: 37px;
  border-radius: 5px;
  -webkit-radius: 5px;
  -moz-border-radius: 5px;
  padding-left: 13px;
  padding-right: 13px;
}

.filebox label {
  font-size: 13px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eff2f7;
  vertical-align: middle;
  cursor: pointer;
  background: #74788d;
  height: 100%;
  line-height: 37px;
  height: 37px;
}

.filebox label:hover {
  background: #382f9c;
}

.filebox input[type='file'] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}

/*file*/
